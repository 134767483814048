import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';

import Slider from '../inc/Slider'
import logomisaa from "../images/logo-300x300.png";
import logomis from "../images/LOGO-MIS_E-300x300.png";
import SrElsyann from "../images/Sister-Elisian_final-1200x1200.jpg";
import SrCicily from "../images/Sister-Cilily_final-1200x1200.jpg";
import SrSolly from "../images/Sister-Solly_final-1200x1202.jpg";
import DrDipayanTarafdar from "../images/president-misaa.jpeg";
import blooddonation from "../images/blood-donation image.jpeg";
import kasemmallick from "../images/kasem-mallick.jpg";
import shibajisarkar from "../images/Shibaji Sarkar new.jpg";
import ScrollToTop from "react-scroll-to-top";
import sistersuperior from "../images/Sister Superior.jpg";
import ReactPlayer from "react-player";

import principal from "../images/principal-1-1200x1200.png"
import AnnieThoma from "../images/Sr-Superior-1-1200x1200.png"
import "./Home.css";
import Gallery from './Gallery';
import Header from '../inc/Header';
import Joinm from './Joinm';
import Joinmisaa from "../images/join-member.jpg";
import misaaInitiative from "../images/misaa Initiative.jpeg";

import { Link } from "react-router-dom";


import { FacebookEmbed } from 'react-social-media-embed';
import CurrentNews from './CurrentNews';
import Gallerypage from './Gallerypage';
import AddFeedback from '../AddFeedback';

import donatebloodimg from "../images/misaa Initiative.jpeg"

function Home() {


  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalOpen(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => setModalOpen(false);



  return (
    <div>
      <Header />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="light" />
      <Slider />

      <div className="icon-bar">
        <a href="" className="facebook mb-2"></a>
        <a href="#" className="blue mb-2"></a>
        <a href="#" className="red mb-2"></a>

        <a style={{ color: "black" }} href="#" className="yellow"></a>
      </div>


      <div style={{
        backgroundImage: "url(" + "" + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
      </div>

      <br />
      <div style={{ color: "Green", margin: 10 }} className="fixed-bottom mt-3"> <a className="homebutton blink" href='Join_membership'>Join Membership </a></div>
      <div className='album py-2'>
        <div className='container'>


          <center><h1>WELCOME TO M.I.S.A.A !!</h1>
            <div className='hometext'>
              <h6 style={{ color: "grey" }}>Our motto “Consciousness, Courage & Compassion. “It is said, “School days are the best days of life”. And this realization comes only when you finish your secondary, higher secondary, graduation and post grad and look back. One can then compare the other experiences in college and later, to that with the school days, and find how golden those days really were, days that will never ever come back in life</h6>
            </div>
          </center>

        </div>

      </div>
      <br />
      <center>
        <a href='Anthem'>
          <div className="buttonanthem" type="submit"><small>OUR ANTHEM</small> </div></a>

      </center>
      <div className="album py-2">
        <div className="container">
          <div className='row'>
            <div className="col-md-5 mt-3">
              <h2>Membership Plan of <b>MISAA</b></h2>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card exofficiocard border-success mb-3" >

                <div className="card-body text-secondary">
                  <img src={Joinmisaa} className="card-img-top" alt="Joinmisaa" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>




      <div className='App'>

        <Modal isOpen={isModalOpen} onRequestClose={closeModal}>

          <div className="album py-2">
            <div className="container">
              <div className="row">
                <br />
                {/* <img src={donatebloodimg} className="mb-3 mt-5" alt="donatebloodimg" />
                <h2 style={{ color: "black" }}>*ALL BRAND <b style={{ color: "grey" }}>UPTO 50%</b> OFF </h2> */}

              </div>
            </div>
          </div>
          <br />
        

          <div className="album py-3">
            <div className="container">
              <div className="row">
              <center>
                <div className="col-md-3">
               
                  <div className="card">
                    <img src={donatebloodimg} className="card-img-top" alt="misaa"/>
                    <div className="card-body">
                  
                      <p style={{color:"maroon"}}  className="card-text textjustify mb-3"> <small>Mankind Pharmaceuticals presents A PCOS ( Polycystic Ovary Syndrome ) Awareness Camp at Mary Immaculate School. It will be held at the school Auditorium on the 19th November 2024, Tuesday from 11 AM to 12:30 PM. 
<br/>
<br/>
The seminar will be for girl students of M.I.S of Classes 9,10,11 & 12. Renowned Gynecologist & M.I.S.A.A President Dr. Dipayan Tarafdar will be the Chairperson of The Seminar and accomplished Gynecologist Dr. Tarana Haque will be the speaker. Sister Principal Jacintha Manjaly will preside over the program. </small></p>
<br/>
<p style={{color:"maroon"}} ><small>A M.I.S.A.A INITIATIVE</small></p>

                     
                    </div>
                  </div>
                
                </div>
                </center>
             
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />



          {/* <div className="album py-2">
        <div className="container">
            <div className="row">

                <Carousel responsive={responsive}>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={muftilogo} alt="blackberrys" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={Loiusphilippe} alt="Loiusphilippe" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={blackberrys} alt="muftilogo" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={VanHeusen} alt="blackberrys" />

                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={Allensolly} alt="Allensolly" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={Parkavenue} alt="Parkavenue" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={spykar} alt="spykar" />

                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={killer} alt="killer" />
                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={turtle} alt="turtle" />
                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={beinghuman} alt="beinghuman" />
                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={mantecarlo} alt="mantecarlo" />
                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={papejeanslondon} alt="papejeanslondon" />
                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={sweetdream} alt="sweetdream" />
                    </div>
                </Carousel>
            </div>
        </div>

    </div> */}
          <div className="d-flex justify-content-end align-items-center mb-3">
            <button className='btn btn-secondary btn-sm mt-2' onClick={closeModal}>Close</button>
          </div>
        </Modal>
      </div>



      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
              <div className="card text-dark bg-info mb-3">
                <div className="card-header"><center><h4><b>LIFETIME MEMBER</b></h4></center></div>
                <center>
                  <div className="card-body">
                    <br />
                    <p className="card-text">Enroll for lifetime membership</p>
                    <br />
                    <Link to="/Lifetime">
                      <div className='btn-grad30'>LIFETIME & VIEW DETAILS</div>
                    </Link>
                  </div>
                </center>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="card text-danger bg-light mb-3">
                <div className="card-header"><center><h4><b>GENERAL MEMBERSHIP</b></h4></center></div>
                <center>
                  <div className="card-body">

                    <br />
                    <p className="card-text mb-4">Enroll for lifetime membership</p>
                    <br />
                    <Link to="/Yearly_member">
                      <button className='lifetimecard'>YEARLY & VIEW DETAILS</button>
                    </Link>
                  </div>
                </center>
              </div>
            </div>

          </div>
        </div>
      </div>


      <br />
      <div className="album py-2">
        <div className="container">
          <center>
            <h2>Membership Eligibility <b>Terms and Conditions</b></h2>
          </center>
          <br />
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Eligibility 1
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h6>To become a MISAA Member, someone who has not passed ICSE or ISC Exams from the School, will have to spend a minimum of 7 years in MIS, Berhampore. However admission can only be considered, when the student has completed Secondary Level Exam from his present school and not before.</h6>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Eligibility 2
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h6>He / She can also become a member  if he / she successfully passes ICSE / ISC exam from MIS Berhampore. If this criteria is fulfilled , then he/she need not spend the minimum tenure period as mentioned in Eligibility Point 1. </h6>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Eligibility 3
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h6>The aspiring member who has not passed ICSE / ISC Exam from this school, will however be required t write a prayer letter addressed to the honorable President or Secretary, MISAA, stating his / her desire to enroll and the right to allow registration and not will the discretion of the honorable President/Secretary, subject to any inquiry they may deem necessary. </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="album py-0">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <img src={logomisaa} className="card-img-top " alt="logomisaa" />
            </div>
            <div className="col-md-8">
              <center>
                <div className="card-body">
                  <h3 className="card-text mt-4">Mary Immaculate School Alumni Association </h3>
                  <h4> Berhampore, Murshidabad</h4>
                </div>
              </center>
            </div>
            <div className="col-md-2">
              <img src={logomis} className="card-img-top" alt="logomisaa" />
            </div>
          </div>
        </div>
      </div>
      <br />



      <br />
      {/* <div className='album py-2'>
        <div className='container'>
          <h3 className=' textjustify mb-3' >ANNUAL BLOOD DONATION CAMP BY Mary Immaculate School Alumni Association. Guru Nanak Ji Jayanti, 27th November 2023 from 10 AM onwards at M.I.S Hall.</h3>
          <h4 className='textjustify mb-3'>রক্তের সম্পর্ক কিন্তু শুধু জন্মসূত্রেই হয়, এমন নয় ! আমরা চাইলেই নতুন, নতুন মানুষের সাথে এই সম্পর্কের অটুট বন্ধনে আবদ্ধ হতে পারি। আসুন রক্তদান করুন, নিজে ভালো থাকুন, ওপর কে জীবনদান করুন।</h4>
          <h4 className='textjustify mb-2'>বহরমপুর তথা মুর্শিদাবাদের সকল সচেতন মানুষ কে রক্তদান করার আবেদন রাখছি। আগামী 27 নভেম্বর 2023, Mary Immaculate School এ আসার আমন্ত্রণ জানাই।</h4>
          <br />
          <img src={blooddonation} className="d-block w-100" alt="blooddonation" />
        </div>

      </div> */}
      <div className="album py-1">

        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className='schoolberhampore'>
                <h2 className='blink mt-2' >M.I.S.A.A <b>Upcoming Events Bulletin</b> :</h2>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="album py-1">

        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="card  text-white bg-black mb-3">

                <div className="card-body">
                  <h6 className="card-title textjustify">1) Taru Rakhi Bandhan. Annual Tree Plantation Initiative on Raksha Bandhan day, 19th August 2024. Everyone are welcome to join. Reporting time 06:30 AM Mary Immaculate School. <br /> <br /></h6>

                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card text-white bg-info mb-3">

                <div className="card-body">
                  <h6 className="card-title textjustify">
                    <center> <h3 style={{ color: "red" }} className='blink'>New</h3> </center>
                    2)  Congratulations To Team India For Bagging Gold In Both Men & Women's Section in The Chess Olympiad At Budapest.



                  </h6>
                  <br />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card text-dark bg-light mb-3">
                <center> <h3 style={{ color: "red" }} className='blink'>New</h3> </center>
                <img src={misaaInitiative} className="card-img-top" alt="misaaInitiative"/>

                <div className="card-body">
                <p style={{color:"maroon"}}  className="card-text textjustify mb-0"> <small>Mankind Pharmaceuticals presents A PCOS ( Polycystic Ovary Syndrome ) Awareness Camp at Mary Immaculate School. It will be held at the school Auditorium on the 19th November 2024, Tuesday from 11 AM to 12:30 PM. 
<br/>
<br/>
The seminar will be for girl students of M.I.S of Classes 9,10,11 & 12. Renowned Gynecologist & M.I.S.A.A President Dr. Dipayan Tarafdar will be the Chairperson of The Seminar and accomplished Gynecologist Dr. Tarana Haque will be the speaker. Sister Principal Jacintha Manjaly will preside over the program. </small></p>
<br/>
<p><small>A M.I.S.A.A INITIATIVE</small></p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card text-white bg-primary mb-3">

                <div className="card-body">
                  <h6 className="card-title  textjustify">
                    <center> <h3 style={{ color: "red" }} className='blink'>New</h3> </center>
                    4) Annual M.I.S.A.A get-together, musical night & dinner on 22nd December 2024 at school grounds. Program starts at 04:30 PM and ends at 09:30 PM. Event is for M.I.S.A.A members only. <br />
                    <br>
                    </br></h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <CurrentNews/> */}
      <br />
      <center>
        <div className='album py-2'>
          <div className='container'>
            <div className="col-md-4">
              <ReactPlayer className="mb-2" width="100%" height="100%" url="https://youtu.be/GewKfbQdHlo?si=IjdHPMQ9rd5TW3XH" />
            </div>
          </div>
        </div>
      </center>
      <br />

      <div className='album py-2'>
        <div className='container'>
          <center>
            <h1 style={{ color: "grey" }}>MIS <b>Administration</b></h1>
          </center>
        </div>
      </div>
      <center>
        <div className="album py-0">
          <div className="container">
            <div className="row">

              <div className="col-md-4 mt-3">

                <div className="card exofficiocard" >
                  <div style={{ color: "darkBlue" }} className="card-header"><center><b> Principal</b></center></div>
                  <img src={principal} className="card-img-top" alt="principal" />
                  <center>
                    <div className="card-body">
                      <h5 className="card-title">Sr. Jacintha Manjaly</h5>
                      <hp className="card-title">Dear cherished M.I.S.A.A members,</hp>
                      <p className="card-text mb-4">I am sure your memories of Mary Immaculate School, are fond ones. Class rooms,
                        corridors, friends, dedicated teachers and staff, and disciplined campus with high
                        spiritual ethos.

                      </p>
                      <br />
                      <a href="Sr.Jacintha" className="morebutton">MORE</a>
                    </div>
                  </center>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="card exofficiocard" >
                  <div style={{ color: "darkBlue" }} className="card-header"><center><b>S.M.I. Sister Superior</b></center></div>
                  <img src={sistersuperior} className="card-img-top" alt="sistersuperior" />
                  <center>
                    <div className="card-body">
                      <h5 className="card-title">Sister Lalita</h5>

                      <p className="card-text mb-2">  Message.</p>
                      <h6 className="card-text"> Dear Members of MISAA</h6>
                      <p>It is with love and gratitude to God our loving Father that I pen up this few lines to appreciate you for the efforts that you have made in serving the humanity through your different philanthropic activities.   </p>
                      <a href="/SisterLalita" className="morebutton">MORE</a>
                    </div>
                  </center>
                </div>
              </div>


            </div>
          </div>
        </div>
      </center>
      <br />
      <br />

      <div className='album py-2'>
        <div className='container'>
          <center>
            <h1 style={{ color: "grey" }}>MISAA'S <b>Ex-Officio</b></h1>
          </center>
        </div>
      </div>
      <div className="album py-0">
        <div className="container">
          <div className="row">
            <center>
              <div className="col-md-4 mt-3">

                <div className="card exofficiocard" >
                  <div className="card-header"><center><b style={{ color: "darkBlue" }} >Chief Advisor</b></center></div>
                  <img src={SrCicily} className="card-img-top" alt="Sr. Cicily" />
                  <center>
                    <div className="card-body">
                      <h5 className="card-title">Sr. Cicily</h5>
                      <p className="card-text">Loving greetings to each and every member of MISAA, and hearty congratulations to the office bearers for successfully establishing the website for speedy growth of activities of MISAA. Prayerful loving wishes.</p>
                      <a href="Sr.Cicily" className="morebutton">MORE</a>
                    </div>
                  </center>
                </div>

              </div>
            </center>


          </div>
        </div>
      </div>
      <br />
      <br />

      <div className='album py-2'>
        <div className='container'>
          <center>
            <h1>Message from Livewire <b>MISAA President</b></h1>
            <p className='mb-0'>Dr. Dipayan Tarafdar </p>
            <div className="album py-2">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 mt-3">

                    <img src={DrDipayanTarafdar} className="card-img-top" alt="DrDipayanTarafdar" />
                    <div className="card-body">


                    </div>

                  </div>
                  <div className="col-md-8 mt-3">


                    <div className="card-body">
                      <p className='textjustify mt-2'>Dr. Dipayan Tarafdar – President of MISAA
                        Tempus Fugit (time Flies) This expression summarizes my feelings about the limitations but also the many blessings of a presidential term.There is a lot to do and certainly a lot one could do .Here it is important to restrict for us to what can be accomplished.So, while I am honoured to have received your trust to handle association matters I am also very humbled by the sense that perhaps more could have been done.
                      </p>
                      <p className='textjustify'>Time spent profitably –working with my gifted colleagues on the executive committee dedicated to pressing issues of concern to our association and its members-is what I will recall most when my term of office concludes.Much effort goes on ´behind the scenes´ and as is indicated below ,there has been considerable progess.We are happy that the association is in good hands.</p>
                      <center>
                        <a href="DrDipayan_Tarafdar" className="morebutton">READ MORE</a>
                      </center>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br />



        </div>
      </div>
      <br />

      <div className="album py-0">
        <div className="container">
          <center><h2><b>Our Gallery</b></h2></center>
        </div>
      </div>
      <Gallerypage />
      {/* <Gallery /> */}
      <br />
      <br />
      <hr />
      <div className="album py-3">
        <div className="container">

          <center>
            <h2>Message from <b style={{ color: "grey" }}>Secretary</b></h2>
          </center>
        </div>
      </div>
      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="card">
                <img src={kasemmallick} className="card-img-top" alt="kasem-mallick" />

              </div>
            </div>
            <div className="col-md-8 mt-3">
              <div className="card-body">
                <h4 className="card-title textjustify mb-3"><b>Kasem Mallick</b>  </h4>


                <h6 className='textjustify mb-3'>I am deeply humbled to have been given the responsibility of secretary of MISAA for a decade,now MISAA is a brand in Berhampore,we are appreciated by the people of Berhampore due to our social and cultural activities</h6>
                <h6 className='textjustify mb-3'>We are increasing our members every year and I would be very much pleased if everyone come forward during our programme apart from their busy schedule</h6>
                <h6 className='textjustify' >I take this opportunity to request the entire Alumini of Mary Immaculate School(MISAA) to keep this valued association strong and steadfast so as to help to elevate the quality of our social and cultural activities.It is my belief that MISAA Will go a long way in ensuring constant improvement of our society. </h6>


              </div>

            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="album py-3">
        <div className="container">

          <center>
            <h2>Message from <b style={{ color: "grey" }}> Joint Secretary</b></h2>
          </center>
        </div>
      </div>
      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="card">
                <img src={shibajisarkar} className="card-img-top" alt="shibaji-sarkar" />

              </div>
            </div>
            <div className="col-md-8 mt-3">


              <div className="card-body">
                <h4 className="card-title textjustify mb-3"><b>Shibaji Sarkar</b>  </h4>

                <h6 className='textjustify mb-3'>Our motto “Consciousness, Courage & Compassion. “It is said, “School days are the best days of life”. And this realization comes only when you finish your secondary, higher secondary, graduation and post grad and look back. One can then compare the other experiences in college and later, to that with the school days, and find how golden those days really were, days that will never ever come back in life. Starting with the kindergarten classes, memories of little colorful benches, rhymes, singing, running around, sometimes falling, crying and learning to get up again, to the transition to school uniform in higher classes, increased discipline, tougher curriculum and finally the tensions of doing well in the school finals, it’s all a journey that is incomparable to anything else in the years to come in anyone’s life.</h6>

              </div>

            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <br />
      <br />


      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
              <div className="card">
                <iframe className="iframe-fluid mb-3" height="300px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Mary%20Immaculate%20School,%2096,%20K.K.%20Banerjee%20Road,%20Raninagar,%20Gora%20Bazar,%20Berhampore,%20West%20Bengal%20742101%20+(Mary%20Immaculate%20School,%2096,%20K.K.%20Banerjee%20Road,%20Raninagar,%20Gora%20Bazar,%20Berhampore,%20West%20Bengal%20742101)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <AddFeedback />
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  )
}

export default Home